import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'hpm-external-partner-update',
  templateUrl: './external-partner-update.component.html',
  styleUrl: './external-partner-update.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalPartnerUpdateComponent {
  getEndpoint(): string {
    return `${environment.baseUrl}/partners`;
  }
}
