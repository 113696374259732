<cx-header>
  <cx-header-title>
    {{ 'ADMIN.HEADER' | translate }}
  </cx-header-title>
</cx-header>
<div class="container">
  <hpm-tab-navigation></hpm-tab-navigation>
  <hpm-product-update></hpm-product-update>
  <hpm-sap-mapping-update></hpm-sap-mapping-update>
  <hpm-external-partner-update></hpm-external-partner-update>
  <hpm-manage-icons></hpm-manage-icons>
  <hpm-word-template-admin></hpm-word-template-admin>
</div>
